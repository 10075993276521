import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { UserContext } from '~contexts/user';
import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';

import Breadcrumb from '~components/breadcrumbs';

import Header from './header';
import About from './about';

const CleaningWork = () => {
  const intl = useIntl();

  useLayout({displayTopCTABar: false, displaySignIn: true });
  useSeo(
    intl.formatMessage({
      id: 'containers.cleaning-work.seo.title',
    })
  );
  const { user } = useContext(UserContext);
  const locId = user?.operations?.address.place_id;

  return (
    <>
      <section className="c-subheader">
        <div className="l-container">
          <Breadcrumb.Wrapper>
            <Breadcrumb.Crumb>
              <Breadcrumb.CrumbLink to={user.accountType === 'client' ? '/' : locId ? `/customers?locationId=${locId}` : '/intake/worker'} index={1}>
                {intl.formatMessage({
                  id: 'breadcrumbs.home',
                })}
              </Breadcrumb.CrumbLink>
            </Breadcrumb.Crumb>
            <Breadcrumb.Crumb>
              <FormattedMessage id="routes.cleaning-work">
                {(msg) => (
                  <Breadcrumb.CrumbLink to={`/${msg}`} index={2}>
                    {intl.formatMessage({
                      id: 'breadcrumbs.cleaning-work',
                    })}
                  </Breadcrumb.CrumbLink>
                )}
              </FormattedMessage>
            </Breadcrumb.Crumb>
          </Breadcrumb.Wrapper>
        </div>
      </section>
      <section className="c-page l-container">
        <Header />
        <About />
      </section>
    </>
  );
};

export default CleaningWork;

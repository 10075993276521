import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { UserContext } from '~contexts/user';

import Button from '~components/button';
import Hero from '~components/hero';

const Header = () => {
  const intl = useIntl();
  const { user } = useContext(UserContext);
  const locId = user?.operations?.address.place_id;

  return (
    <Hero.Wrapper>
      <Hero.Title>
        {intl.formatMessage({
          id: 'containers.cleaning-work.seo.hero.title',
          defaultMessage: 'Find a great cleaner without hassle',
        })}
      </Hero.Title>
      <Hero.Buttons>
        <Link to={user.accountType === 'client' ? '/' : locId ? `/customers?locationId=${locId}` : '/intake/worker'}>
          <Button>
            {intl.formatMessage({
              id: 'containers.cleaning-work.seo.hero.buttons.clients',
              defaultMessage: "I'm looking for a client",
            })}
          </Button>
        </Link>
      </Hero.Buttons>
      <Hero.Intro>
        {intl.formatMessage({
          id: 'containers.cleaning-work.seo.hero.intro.paragraph2',
          defaultMessage:
            'Are you looking for a job in cleaning services? On this platform you can advertise yourself as easy as pie and completely free. Get in touch with potential new customers.',
        })}
      </Hero.Intro>
    </Hero.Wrapper>
  );
};

export default Header;

import React from 'react';
import { useIntl } from 'react-intl';

import AboutPlatform from '~components/about-platform';

const About = () => {
  const intl = useIntl();

  return (
    <AboutPlatform
      alt={intl.formatMessage({
        id: 'containers.cleaning-work.seo.about.image-alt',
        defaultMessage: 'Domestic help',
      })}
      headline={intl.formatMessage({
        id: 'containers.cleaning-work.seo.about.headline',
        defaultMessage: 'About this platform',
      })}
      content={intl.formatMessage({
        id: 'containers.cleaning-work.seo.about.content',
        defaultMessage: '',
      })}
      button={intl.formatMessage({
        id: 'containers.cleaning-work.seo.about.button',
        defaultMessage: 'Learn more',
      })}
    />
  );
};

export default About;
